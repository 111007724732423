<script setup>
import { ref } from "vue";
import ComoFuncionaGarcom from "./ComoFuncionaGarcom.vue";
import Faq from "../../components/Faq.vue";
import SectionBannerV2 from "../../components/SectionBannerV2.vue";
import Resgate from "./Resgate.vue";
import PontosPorVideo from "./PontosPorVideo.vue";

const faq = ref([
  {
    question: 'A cada garrafa aberta com o abridor do Bees da Chefia você ganha pontos para resgatar no PicPay!',
    answer: 'A cada garrafa aberta com o abridor do Bees da Chefia você ganha pontos para resgatar no PicPay!',
    id: 2
  },
])
</script>
<template>
  <div class="container">
    <SectionBannerV2 :show-cat="true" />
    <section class="pb-5 bg-primary">

      <iframe
src="https://www.youtube.com/embed/Ia-JSbTzFMY?si=dB2DK7dbFCr-rUft" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen class="video"></iframe>
    </section>

    <ComoFuncionaGarcom />

    <Resgate />

    <PontosPorVideo />

    <Faq :faq="faq" />

  </div>
</template>

<style scoped>

.video {
  width: 100%;
  height: 445px;
}



@media(max-width:768px) {
  .area-picpay {
    flex-direction: column;
  }

  .area-l-mask {
    width: 270px;
    height: 300px;

  }


  .cel {
    display: none;
  }

  .numbers {
    font-size: 32px;
    height: 58px;
  }
}
</style>
