<script setup>
import { ref } from "vue";
import Faq from "../../components/Faq.vue";
import SectionBannerV2 from "../../components/SectionBannerV2.vue";
import ComoFuncionaBar from "./ComoFuncionaBar.vue";
import RecebeHub from "./RecebeHub.vue";
import AvisoHub from "./AvisoHub.vue";

const faq = ref([
  {
    question: 'A cada garrafa aberta com o abridor do Bees da Chefia você ganha pontos para resgatar no PicPay!',
    answer: 'A cada garrafa aberta com o abridor do Bees da Chefia você ganha pontos para resgatar no PicPay!'
  },
])
</script>
<template>
  <div class="container">
    <SectionBannerV2 :show-cat="true" />
    <section class="pb-5 bg-primary">
      <iframe src="https://www.youtube-nocookie.com/embed/qjw9LJxLSr4" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen class="video"></iframe>

    </section>

    <ComoFuncionaBar />


    <RecebeHub />

    <AvisoHub />

    <Faq :faq="faq" />

  </div>
</template>

<style scoped>
.video {
  width: 100%;
  height: 445px;
}

@media(max-width:768px) {
  .img-r {
    display: none;
  }

  .area-hub {
    flex-direction: column;
  }

  .area-picpay {
    flex-direction: column;
  }

  .area-l {
    padding-left: 9px;
  }

  .area-l-mask {
    width: 270px;
    height: 300px;

  }



}
</style>