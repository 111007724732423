import { defineStore } from 'pinia'
import { http } from '@/services/api';

import { validaCpfCnpj } from "@/utils/validacpfCnpj";
import { auth } from "@/store/auth";
import { useCadastroPdv } from "@/store/cadastroPdv";

import router from '../router'
let authStore

setTimeout(() => {
    authStore = auth();

}, 100);

export const useCadastroProcurador = defineStore('cadastroProcurador', {
    state: () =>
    ({
        isMobile: false,
        loading: false,
        form: {
            cnpj: "",
            nome: "",
            cpf: "",
            procuracao: "",
        },
    }),
    actions: {

        ActionCleanState() {
            this.loading = false
            this.form = {
                cnpj: "",
                cpf: "",
                nome: "",
                procuracao: "",

            }
        },

        handleisMobile() {
            this.isMobile = window.innerWidth < 768;
        },


        checkNomeCompleto() {
            let valido = this.form.nome.trim().split(' ').length > 1

            if (!valido) {
                window.__Swal.fire({
                    icon: "warning",
                    title: "Atenção",
                    text: 'Preencher o nome completo',
                });
            }
        },

        validaCnpjCpf(doc) {
            let valido = validaCpfCnpj(
                doc.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "")
            );

            if (!valido) {
                if (this.selectedStep === 1) {
                    this.form.cnpj = "";
                } else {
                    this.form.cpf = "";
                }
                window.__Swal.fire({
                    icon: "warning",
                    title: "Atenção",
                    text: `${this.selectedStep === 1 ? "CNPJ" : "CPF"
                        } inválido`,
                });
            }
        },

        limpaMask(str) {
            return str ? str.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "").replace(/\(/g, "").replace(" ", "") : ''
        },

        sendForm(payload) {
            const url = 'registerProcurador'
            const form = new FormData()
            form.append('cnpj', payload.cnpj)
            form.append('nome_procurador', payload.nome)
            form.append('cpf', payload.cpf)
            form.append('procuracao', this.form.procuracao)

            return http.post(url, form).then((r) => {
                authStore.user = r.data.data
                authStore.token = r.data.token
                return r.data
            })
        },

        async handleCadastro() {
            try {
                this.loading = true 
                let formCadastro = JSON.parse(JSON.stringify(this.form));            
                formCadastro.cpf = this.limpaMask(formCadastro.cpf)
                formCadastro.cnpj = this.limpaMask(formCadastro.cnpj)
                const res = await this.sendForm(formCadastro)

                window.__Swal.fire({
                    icon: "success",
                    title: "Sucesso!",
                    text: res.message,
                }).then(() => {
                    const cadastradoPdv = useCadastroPdv();
                    cadastradoPdv.ActionCleanState()
                    this.handleFinish()
                })

            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false 
            }

        },

        handleFinish() {
            this.ActionCleanState()
            router.push({ name: 'Login' })
        },

        handleImg(event) {
            let files = event.target.files || event.dataTransfer.files;
            if (!files.length) return;
            this.form.procuracao = files[0] 
        }
    },
})