<template>
  <form class="m-auto d-flex flex-column justify-content-between form mt-5">
    <div>
      <h1 class="text-center mb-4">Preencha os dados do Procurador</h1>
      <div class="input-group input-group-lg mb-3 error">
        <input
        v-model="cadastradoProcurador.form.cnpj"
          v-maska="'##.###.###/####-##'"
          disabled
          type="text"
          class="form-control"
          placeholder="CNPJ"
          @blur="
            cadastradoProcurador.validaCnpjCpf(cadastradoProcurador.form.cnpj)
          "
        />
      </div>
      <form>
        <div class="input-group input-group-lg mb-3">
          <input
            v-model="cadastradoProcurador.form.nome"
            autocomplete="off"
            type="text"
            class="form-control"
            placeholder="Nome completo*"
            @blur="cadastradoProcurador.checkNomeCompleto()"
          />
        </div>
      </form>
      <div class="input-group input-group-lg mb-3">
        <input
          v-model="cadastradoProcurador.form.cpf"
          v-maska="'###.###.###-##'"
          disabled
          type="text"
          class="form-control"
          placeholder="CPF"
          @blur="
            cadastradoProcurador.validaCnpjCpf(cadastradoProcurador.form.cpf)
          "
        />
      </div>
      <div class=" mb-3">
        <div>
          <label for="formFileLg" class="form-label"
            >Anexar cópia da procuração*</label
          >
          <input
            id="formFileLg"
            class="form-control form-control-lg"
            type="file"
            @change="cadastradoProcurador.handleImg($event)"
          />
        </div>
      </div>

      <button
        type="button"
        :disabled="loading || disabledSend"
        class="btn w-100 rounded-pill"
        @click.prevent="cadastradoProcurador.handleCadastro"
      >
        <div
          v-if="cadastradoProcurador.loading"
          style="width: 1rem; height: 1rem"
          class="spinner-border text-white"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <span v-else>Continuar</span>
      </button>
      <!-- <div>
          Já tem cadastro?
          <router-link :to="{ name: 'Login' }"
            ><strong>Clique aqui.</strong></router-link
          >
        </div> -->
    </div>
  </form>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useCadastroProcurador } from "../../store/cadastroProcurador";

const cadastradoProcurador = useCadastroProcurador();
const router = useRouter()
const disabledSend = computed(() => {
  let disabled = true;
 if (
    !cadastradoProcurador.form.nome ||
    !cadastradoProcurador.form.procuracao
  ) {
    disabled = true;
  } else {
    disabled = false;
  }

  return disabled;
});

onMounted(() => {
  if (!cadastradoProcurador.form.cpf || !cadastradoProcurador.form.cnpj) {
    router.push({name: 'FormPdv'})
  }
});
</script>

<style scoped>
h1 {
  font-size: 30px;
  color: #000218;
  text-align: left;
}

.btn-modal-confirm {
  width: 100%;
  max-width: 202px;
}

.n-step {
  font-weight: bold;
  font-size: 25px;
  background-color: #e6ecf2;
  height: 39px;
  width: 39px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccd2d7;
}

.n-step.selected {
  font-size: 30px;
  background-color: #272727;
  height: 46px;
  width: 46px;

  color: #fff;
}

.form {
  width: 100%;
  max-width: 550px;
}

.btn {
  padding: 0.75rem;
  font-size: 16px;
  background-color: #272727;
  color: #fff;
}

.btn.chat {
  background-color: transparent;
  color: #18a76a;
  border: solid 1px #18a76a;
  width: 185px;
}

.text-gray-100 {
  color: #707070;
}

a:hover {
  text-decoration: underline;
  color: red !important;
}

.periodo .form-control {
  max-width: 198px;
}

ul {
  list-style-type: none;
}

.text-bold {
  font-weight: bold;
}

.politica-area {
  max-height: 683px;
  overflow: auto;
}

.politica-area p {
  line-height: 1.4;
  margin-bottom: 10px !important;
  font-size: 0.9rem;
}

.modal-body- p {
  font-size: 0.9rem;
  line-height: 1.3;
}
</style>
