<template>
  <section class="py-5 bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <img class="mobile-none" src="../assets/img/Destac-left.png" alt="destaque">
      <h1>Dúvidas frequentes:</h1>
      <img class="mobile-none" src="../assets/img/Destac-right.png" alt="destaque">
    </div>

    <div v-for="faqItem in props.faq" :key="faqItem.id" class="area-faq">
      <div @click="toggleAccordion(faqItem.id)" :class="{ active: activeAccordion === faqItem.id }">
        <p class="question">{{ faqItem.question }}</p>
        <div class="answer-wrapper" >
          <p class="answer px-4">{{ faqItem.answer }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  faq: { type: Array, default: null },
});

const activeAccordion = ref(null);

function toggleAccordion(id) {
  if (activeAccordion.value === id) {
    activeAccordion.value = null;
  } else {
    activeAccordion.value = id;
  }
}
</script>

<style scoped>
h1 {
  font-size: 30px;
  color: #FFF;
  background-color: #000;
  display: inline-block;
  padding: 5px 15px;
  margin: auto;
}

.area-faq {
  padding: 0 110px;
}

.area-faq>div {
  border-bottom: solid 1px #707070;
  cursor: pointer;
  position: relative;
}

.area-faq>div.active .answer-wrapper {
  height: auto;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.question {
  font-size: 27px;
  font-weight: bold;
}

.answer-wrapper {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.answer {
  font-size: 27px;
  font-weight: 100;
  max-width: 1010px;
}

@media (max-width: 768px) {
  .mobile-none {
    display: none;
  }

  .area-faq {
    padding: 0;
  }
}

@media (max-width: 490px) {}
</style>