<template>
    <section class="bg-white py-5 section-resgate position-relative d-flex justify-content-between area-hub">
        <div class="area-l">
            <div>
                <h1>Você está recebendo:</h1>
            </div>
            <p class="text-recebe">Uma <strong>caixa personalizada</strong> exclusiva Bees da
                Chefia contendo os abridores inteligentes
                + 1 Hub de Conexão portátil.</p>

            <p class="text-recebe">
                O <strong>HUB portátil</strong> pode ser conectado direto na
                Tomada, e possui 7 entradas de conexão.
                É mais praticidade para o estabelecimento
                Na hora de carregar os abridores.
            </p>
        </div>
        <img class="img-r" src="../../assets/img/HubAbridor.svg" alt="App">
        <div class="area-l-mask"></div>
    </section>
</template>

<style scoped>
.section-resgate h1 {
    color: #000;
    background-color: transparent;
    max-width: 540px;
}

.area-l {
    display: inline-block;
    padding-left: 64px;
    padding-bottom: 29px;
    padding-top: 47px;
    width: 100%;
    max-width: 822px;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    position: relative;
    z-index: 12;
    background-color: #FFFF00;

}

.area-l-mask {
    display: block;
    width: 870px;
    height: 300px;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    background-color: #000;
    position: absolute;
    top: 18px;
    left: 0;
    z-index: 0;
}

.area-hub .text-recebe {
    font-size: 24px;
    font-weight: 400;
    max-width: 471px;
}

.img-r {
    bottom: 0;
    position: absolute;
    right: 191px;
    z-index: 1002;
}

@media(max-width:768px) {
    .img-r {
        display: none;
    }

    .area-hub {
        flex-direction: column;
    }

    .area-l {
        padding-left: 9px;
    }

    .area-l-mask {
        width: 270px;
        height: 300px;

    }
}
</style>