<template>
  <section class="pt-5 ps-6 area-aviso d-flex flex-column flex-lg-row">
    <img class="img-fluid" src="../../assets/img/Ganhe-pontos-video.png"
      alt="Ganhar pontos ao assistir vídeos do programa">

    <div class="area-info-video flex-1 d-flex flex-column flex-lg-row justify-lg-content-between">
      <img class="img-fluid" src="../../assets/img/Cel-abelha.png" alt="Celular e abelha">

      <div class="d-flex flex-column align-items-center justify-content-center w-100">
        <p class="h4">A cada treinamento
          concluído você acumula
          mais pontos!</p>

        <div class="card d-inline-flex flex-column p-3">
          <div class="text-center">
            <p class="numbers">+5.000</p>
            <p class="desc">pontos no Bees da Chefia</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.h4 {
  max-width: 244px;
}

.area-info-video {
  background-color: #FFFF00;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  padding: 36px;
}

.desc {
  font-weight: 600;
  padding: 0 15px;
}

.numbers {
  font-size: 71px;
  font-weight: bold;
  height: 94px;
}

.area-aviso {
  background-color: #000;
}


@media(max-width:768px) {
  .numbers {
    font-size: 32px;
    height: 58px;
  }
}</style>