<template>
  <form
    class="m-auto d-flex flex-column"
    :class="{
      ['justify-content-between']: cadastradoPdv.selectedStep !== 5,
      form: cadastradoPdv.selectedStep !== 4,
    }"
  >
    <div class="">
      <p class="text-center text-bold">
        Quer saber como funciona?
        <span
          class="text-gray underline cursor-pointer"
          @click.prevent="cadastradoPdv.showInstrucoes = true"
          >Clique aqui!</span
        >
      </p>
      <div
        v-if="cadastradoPdv.selectedStep !== 5"
        class="d-flex align-items-center justify-content-center gap-5 my-5"
      >
        <button
          v-for="numero in 4"
          :key="String(numero)"
          :class="{ selected: cadastradoPdv.selectedStep === numero }"
          class="n-step"
          @click.prevent
        >
          {{ numero }}
        </button>
      </div>
      <div v-if="cadastradoPdv.selectedStep === 1">
        <h1 class="text-center mb-4">Preencha os dados do PDV:</h1>
        <div
          v-if="!cadastradoPdv.searchCNPJ"
          class="input-group input-group-lg mb-3"
        >
          <input
            v-model="cadastradoPdv.form.estabelecimento.nome_estabelecimento"
            :disabled="cadastradoPdv.disabled.nome_estabelecimento"
            autocomplete="off"
            type="text"
            class="form-control"
            placeholder="Nome do estabelecimento"
          />
        </div>
        <div class="input-group input-group-lg mb-3">
          <input
            v-model="cadastradoPdv.form.estabelecimento.cnpj"
            v-maska="'##.###.###/####-##'"
            :disabled="cadastradoPdv.disabled.cnpj"
            type="text"
            class="form-control"
            placeholder="CNPJ"
            @blur="
              cadastradoPdv.validaCnpjCpf(
                cadastradoPdv.form.estabelecimento.cnpj
              )
            "
          />
        </div>        
        <div v-if="!cadastradoPdv.searchCNPJ">
          <div class="input-group input-group-lg mb-3">
            <input
              v-model="cadastradoPdv.form.estabelecimento.cep"
              v-maska="'#####-###'"
              :disabled="cadastradoPdv.disabled.cep"
              type="text"
              class="form-control"
              placeholder="CEP"
              @blur="cadastradoPdv.buscaCep"
            />
          </div>
          <div class="input-group input-group-lg mb-3">
            <input
              v-model="cadastradoPdv.form.estabelecimento.rua"
              :disabled="cadastradoPdv.disabled.rua"
              type="text"
              class="form-control"
              placeholder="AV. Nações Unidas 18801"
            />
          </div>
          <div class="input-group input-group-lg mb-3">
            <input
              v-model="cadastradoPdv.form.estabelecimento.numero"
              type="text"
              class="form-control"
              :disabled="cadastradoPdv.disabled.numero"
              placeholder="Número"
            />
          </div>
          <div class="input-group input-group-lg mb-3">
            <input
              v-model="cadastradoPdv.form.estabelecimento.complemento"
              :disabled="cadastradoPdv.disabled.complemento"
              type="text"
              class="form-control"
              placeholder="Complemento"
            />
          </div>
          <div class="input-group input-group-lg mb-3">
            <input
              v-model="cadastradoPdv.form.estabelecimento.email"
              type="email"
              class="form-control"
              placeholder="E-mail"              
            />
          </div>
          <div class="input-group input-group-lg mb-3">
            <input
              v-model="
                cadastradoPdv.form.estabelecimento.telefone_estabelecimento
              "
              v-maska="'(##) #####-####'"
              :disabled="cadastradoPdv.disabled.telefone_estabelecimento"
              type="text"
              class="form-control"
              placeholder="Telefone com DDD"
            />
          </div>
          <p class="text-bolder h4 text-center">
            Indique os períodos os quais seu estabelecimento está aberto e os
            garçons do turno:
          </p>
          <div class="row periodo my-3">
            <div
              class="col-6 d-flex align-items-center justify-content-between mb-2"
            >
              <p class="text-bold me-3">Manhã</p>
              <select
                v-model="cadastradoPdv.form.periodo.manha.aberto"
                placeholder="Selecione"
                class="form-control"
                name="n_garcom"
                @change="
                  () => {
                    cadastradoPdv.form.periodo.manha.aberto
                      ? (cadastradoPdv.form.periodo.manha.nGarcons = 1)
                      : (cadastradoPdv.form.periodo.manha.nGarcons = 0);
                  }
                "
              >
                <option :value="false">Fechado</option>
                <option :value="true">Aberto</option>
              </select>
            </div>
            <div class="col-6">
              <input
                v-model="cadastradoPdv.form.periodo.manha.nGarcons"
                :disabled="
                  !cadastradoPdv.form.periodo.manha.aberto &&
                  cadastradoPdv.form.periodo.manha.nGarcons
                "
                type="number"
                :min="cadastradoPdv.form.periodo.manha.aberto ? 1 : 0"
                :max="10"
                class="form-control"
                placeholder="Nº de Garçons"
                @blur="cadastradoPdv.handleLimitGarcons"
              />
            </div>
            <div
              class="col-6 d-flex align-items-center justify-content-between mb-2"
            >
              <p class="text-bold me-3">Tarde</p>
              <select
                v-model="cadastradoPdv.form.periodo.tarde.aberto"
                placeholder="Selecione"
                class="form-control"
                name="n_garcom"
                @change="
                  () => {
                    cadastradoPdv.form.periodo.tarde.aberto
                      ? (cadastradoPdv.form.periodo.tarde.nGarcons = 1)
                      : (cadastradoPdv.form.periodo.tarde.nGarcons = 0);
                  }
                "
              >
                <option :value="false">Fechado</option>
                <option :value="true">Aberto</option>
              </select>
            </div>
            <div class="col-6">
              <input
                v-model="cadastradoPdv.form.periodo.tarde.nGarcons"
                :disabled="
                  !cadastradoPdv.form.periodo.tarde.aberto &&
                  cadastradoPdv.form.periodo.tarde.nGarcons
                "
                type="number"
                :min="cadastradoPdv.form.periodo.tarde.aberto ? 1 : 0"
                :max="10"
                class="form-control"
                placeholder="Nº de Garçons"
                @blur="cadastradoPdv.handleLimitGarcons"
              />
            </div>
            <div
              class="col-6 d-flex align-items-center justify-content-between mb-2"
            >
              <p class="text-bold me-3">Noite</p>
              <select
                v-model="cadastradoPdv.form.periodo.noite.aberto"
                placeholder="Selecione"
                class="form-control"
                name="n_garcom"
                @change="
                  () => {
                    cadastradoPdv.form.periodo.noite.aberto
                      ? (cadastradoPdv.form.periodo.noite.nGarcons = 1)
                      : (cadastradoPdv.form.periodo.noite.nGarcons = 0);
                  }
                "
              >
                <option :value="false">Fechado</option>
                <option :value="true">Aberto</option>
              </select>
            </div>
            <div class="col-6">
              <input
                v-model="cadastradoPdv.form.periodo.noite.nGarcons"
                :disabled="
                  !cadastradoPdv.form.periodo.noite.aberto &&
                  cadastradoPdv.form.periodo.noite.nGarcons
                "
                type="number"
                :min="cadastradoPdv.form.periodo.noite.aberto ? 1 : 0"
                :max="10"
                class="form-control"
                placeholder="Nº de Garçons"
                @blur="cadastradoPdv.handleLimitGarcons"
              />
            </div>
          </div>
          <div class="mb-4">
            <p class="text-bolder h4 text-center mt-4">
              Possui wi-fi no estabelecimento?
            </p>
            <div class="d-flex align-items-center gap-2">
              <label class="d-flex align-items-center gap-1">
                <p>Sim</p>
                <input
                  v-model="cadastradoPdv.form.estabelecimento.wifi"
                  type="radio"
                  name="wifi"
                  value="Sim"
                />
              </label>
              <label class="d-flex align-items-center gap-1">
                <p>Não</p>
                <input
                  v-model="cadastradoPdv.form.estabelecimento.wifi"
                  type="radio"
                  name="wifi"
                  value="Não"
                />
              </label>
            </div>
          </div>
        </div>
        <button
          type="button"
          :disabled="disabledStep"
          class="btn w-100 rounded-pill"
          @click.prevent="cadastradoPdv.handleCadastro"
        >
          <div
            v-if="cadastradoPdv.loading"
            style="width: 1rem; height: 1rem"
            class="spinner-border text-secondary"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Continuar</span>
        </button>
        <!-- <div>
          Já tem cadastro?
          <router-link :to="{ name: 'Login' }"
            ><strong>Clique aqui.</strong></router-link
          >
        </div> -->
      </div>
      <div v-if="cadastradoPdv.selectedStep === 2">
        <h1 class="text-center mb-4">Responsável pelo PDV:</h1>
        <form v-if="!cadastradoPdv.searchCPF">
          <div class="input-group input-group-lg mb-3">
            <input
              v-model="cadastradoPdv.form.responsavel.nome"
              :disabled="cadastradoPdv.disabled.nome"
              autocomplete="off"
              type="text"
              class="form-control"
              placeholder="Nome completo do Responsável"
              @blur="cadastradoPdv.checkNomeCompleto()"
            />
          </div>
        </form>
        <div class="input-group input-group-lg">
          <input
            v-model="cadastradoPdv.form.responsavel.cpf"
            v-maska="'###.###.###-##'"
            :disabled="cadastradoPdv.disabled.cpf"
            type="text"
            class="form-control"
            placeholder="CPF"
            @blur="
              cadastradoPdv.validaCnpjCpf(cadastradoPdv.form.responsavel.cpf)
            "
          />
        </div>
        <p class="small mb-3"><strong>Importante:</strong> Somente CPF dos responsáveis legais que constem no contrato social como administradores poderão assinar.</p>
        <!-- Se o cpf nao esta para o cnpj, habilita cadastro de procurador -->
        <div v-if="cadastradoPdv.add_procurador">
        <p class="">O CPF informado não possui responsabilidade pelo estabelecimento, se você é representante e possui uma procuração, <strong><router-link :to="{name: 'FormProcurador'}">clique aqui</router-link></strong></p>
      </div>

        <div v-if="!cadastradoPdv.searchCPF">
          <div class="input-group input-group-lg mb-3">
            <input
              v-model="cadastradoPdv.form.responsavel.telefone"
              v-maska="'(##) #####-####'"
              type="text"
              class="form-control"
              placeholder="Celular com DDD"
            />
          </div>
          <div class="input-group input-group-lg mb-3">
            <input
              v-model="cadastradoPdv.form.responsavel.email"
              type="email"
              class="form-control"
              placeholder="E-mail"
              @blur="cadastradoPdv.checkEmail"
            />
          </div>
          <div class="input-group input-group-lg mb-3">
            <input
              v-model="cadastradoPdv.form.responsavel.password"
              type="password"
              class="form-control"
              placeholder="Crie sua senha"
            />
          </div>
          <div class="mb-3">
            <p>Onde deseja receber o TOKEN de confirmação</p>
            <label class="text-start me-3">
              SMS
              <input
                v-model="cadastradoPdv.form.signature_method"
                name="recebe_token"
                type="radio"
                value="sms"
              />
            </label>
            <label class="text-start">
              Email
              <input
                v-model="cadastradoPdv.form.signature_method"
                name="recebe_token"
                type="radio"
                value="email"
              />
            </label>
          </div>
          <p class="small">Confirme o {{cadastradoPdv.form.signature_method === 'sms' ? 'telefone' : 'email'}}</p>
          <div v-if="cadastradoPdv.form.signature_method === 'email'" class="input-group input-group-lg">
            <input
              v-model="confirma_email"
              type="email"
              class="form-control"
              placeholder="E-mail"
            />
          </div>
          <div v-else class="input-group input-group-lg">
            <input
              v-model="confirma_telefone"
              v-maska="'(##) #####-####'"
              type="text"
              class="form-control"
              placeholder="Telefone"
            />
          </div>

          <p v-if="errorEmail && cadastradoPdv.form.signature_method === 'email'" class="small text-danger">O email esta diferente do email cadastrado acima!</p>
          <p v-if="errorTelefone && cadastradoPdv.form.signature_method === 'sms'" class="small text-danger">O número esta diferente do número cadastrado acima!</p>

          <p class="text-center mt-3">
            Por favor, confirme se os dados acima estão corretos antes de
            prosseguir.
          </p>
          <p class="small text-center text-orange">
            Você irá receber um TOKEN via {{cadastradoPdv.form.signature_method === 'sms' ? 'telefone no celular' : 'E-MAIL no email'}} informado acima para
            concluir sua assinatura
          </p>
        </div>
        <button
          type="button"
          :disabled="disabledStep2"
          class="btn w-100 rounded-pill mt-5"
          @click.prevent="cadastradoPdv.handleCadastro"
        >
          <div
            v-if="cadastradoPdv.loading"
            style="width: 1rem; height: 1rem"
            class="spinner-border text-secondary"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Continuar</span>
        </button>
      </div>
      <div v-if="cadastradoPdv.selectedStep === 3">
        <h1 class="text-center mb-4">
          Termos de uso e Politica de privacidade
        </h1>
        <div class="politica-area">
          <p>
            Este site é mantido e operado por Agência de Trade Marketing
            Promocional Ltda Nós coletamos e utilizamos alguns dados pessoais
            que pertencem àqueles que utilizam nosso site. Ao fazê-lo, agimos na
            qualidade de controladordesses dados e estamos sujeitos às
            disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de
            Dados Pessoais - LGPD) Nós cuidamos da proteção de seus dados
            pessoais e, por isso, disponibilizamos esta política de privacidade,
            que contém informações importantes sobre
          </p>

          <ul>
            <li>- Quem deve utilizar nosso site</li>
            <li>- Quais dados coletamos e o que fazemos com eles</li>
            <li>- Seus direitos em relação aos seus dados pessoais; e</li>
            <li>- Como entrar em contato conosco.</li>
          </ul>

          <h6 class="text-bold">1. Quem deve utilizar nosso site</h6>
          <p>
            Nosso site só deve ser utilizado por pessoas com mais de dezoito
            anos de idade. Sendo assim, crianças e adolescentes não devem
            utilizá-lo.
          </p>

          <h6 class="text-bold">2. Dados que coletamos e motivos da coleta</h6>
          <p>
            Nosso site coleta e utiliza alguns dados pessoais de nossos
            usuários, de acordo com o disposto nesta seção.
          </p>

          <h6 class="text-bold">
            1. Dados pessoais fornecidos expressamente pelo usuário
          </h6>
          <p>
            Nós coletamos os seguintes dados pessoais que nossos usuários nos
            fornecem expressamente ao utilizar nosso site:
          </p>

          <h6 class="text-bold">PESSOAS FÍSICAS</h6>
          <ul>
            <li>- Nome completo</li>
            <li>- CPF</li>
            <li>- Endereço Completo</li>
            <li>- Telefone Celular</li>
            <li>- Telefone Fixo</li>
            <li>- Estado Civil</li>
          </ul>

          <h6 class="text-bold">PESSOAS JURÍDICAS</h6>
          <ul>
            <li>- Razão Social</li>
            <li>- CNPJ</li>
            <li>- Endereço Completo</li>
            <li>- Telefone Celular</li>
            <li>- Telefone Fixo</li>
            <li>- Nome, CPF e RG dos Sócios</li>
          </ul>

          <p>A coleta destes dados ocorre nos seguintes momentos:</p>
          <ul>
            <li>
              - Quando utiliza os formulários de Cadastro e termos de
              Participação.
            </li>
          </ul>

          <p>
            Os dados fornecidos por nossos usuários são coletados com as
            seguintes finalidades:
          </p>
          <ul>
            <li>
              - Para que as pessoas físicas e jurídicas possam participar da
              Promoção.
            </li>
          </ul>

          <h6 class="text-bold">2. Dados pessoais obtidos de outras formas</h6>
          <p>Nós coletamos os seguintes dados pessoais de nossos usuários:</p>
          <ul>
            <li>- Endereço de IP</li>
            <li>- Dados de Geo Localização</li>
            <li>- Transações efetivadas através do site</li>
          </ul>

          <p>A coleta destes dados ocorre nos seguintes momentos:</p>
          <ul>
            <li>- Quando o usuário faz login</li>
            <li>- Quando o usuário assina os documentos através do site</li>
            <li>- Quando solicita ajuda no fale conosco</li>
          </ul>

          <p>Estes dados são coletados com as seguintes finalidades:</p>
          <ul>
            <li>
              - Garantir autenticidade e segurança nas transações e nos sorteios
              da promoção
            </li>
            <li>
              - Consulta de documentos entregues a partir do cadastro nos
              formulários
            </li>
            <li>
              - Relatórios e análises de índices de participação por geo
              localização
            </li>
            <li>
              - Auditoria de resultados a partir das regras do regulamento
            </li>
          </ul>

          <h6 class="text-bold">3. Dados sensíveis</h6>
          <p>
            Não serão coletados dados sensíveis de nossos usuários, assim
            entendidos aqueles definidos nos arts. 11 e seguintes da Lei de
            Proteção de Dados Pessoais. Assim, não haverá coleta de dados sobre
            origem racial ou étnica, convicção religiosa, opinião política,
            filiação a sindicato ou a organização de caráter religioso,
            filosófico ou político, dado referente à saúde ou à vida sexual,
            dado genético ou biométrico, quando vinculado a uma pessoa natural.
          </p>

          <h6 class="text-bold">4. Cookies</h6>
          <p>
            Cookies são pequenos arquivos de texto baixados automaticamente em
            seu dispositivo quando você acessa e navega por um site. Eles
            servem, basicamente, para seja possível identificar dispositivos,
            atividades e preferências de usuários.
          </p>

          <p>
            Os cookies não permitem que qualquer arquivo ou informação sejam
            extraídos do disco rígido do usuário, não sendo possível, ainda,
            que, por meio deles, se tenha acesso a informações pessoais que não
            tenham partido do usuário ou da forma como utiliza os recursos do
            site.
          </p>

          <h6 class="text-bold">Cookies de terceiros</h6>
          <p>
            Alguns de nossos parceiros podem configurar cookies nos dispositivos
            dos usuários que acessam nosso site.
          </p>
          <p>
            Estes cookies em geral, visam possibilitar que nossos parceiros
            possam oferecer seu conteúdo e seus serviços ao usuário que acessa
            nosso site de forma personalizada, por meio da obtenção de dados de
            navegação extraídos a partir de sua interação com o site.
          </p>
          <p>
            O usuário poderá obter mais informações sobre os cookies de terceiro
            e sobre a forma como os dados obtidos a partir dele são tratados,
            além de ter acesso à descrição dos cookies utilizados e de suas
            características, acessando o seguinte link:
          </p>

          <p>Google Analitycs</p>

          <p>
            As entidades encarregadas da coleta dos cookies poderão ceder as
            informações obtidas a terceiros.
          </p>

          <h6 class="text-bold">Gestão de cookies</h6>
          <p>
            O usuário poderá se opor à utilização de cookies pelo site, bastando
            que os desative no momento em que começa a utilizar o serviço,
            seguindo as seguintes instruções:
          </p>
          <p>
            Assim que o usuário entra no site aparece um pop up informando a
            utilização de cookies o qual é permitido não aceitar ou aceitar nos
            botões abaixo.
          </p>
          <p>
            A desativação de todos os cookies, não entanto, não será possível,
            uma vez que alguns deles são essenciais para que o site funcione
            corretamente.
          </p>
          <p>
            Além disso, a desativação dos cookies que podem ser desabilitados
            poderá prejudicar a experiência do usuário, uma vez que informações
            utilizadas para personalizá-la deixarão de ser utilizadas.
          </p>

          <h6 class="text-bold">
            5. Coleta de dados não previstos expressamente
          </h6>
          <p>
            Eventualmente, outros tipos de dados não previstos expressamente
            nesta Política de Privacidade poderão ser coletados, desde que sejam
            fornecidos com o consentimento do usuário, ou, ainda, que a coleta
            seja permitida com fundamento em outra base legal prevista em lei.
          </p>
          <p>
            Em qualquer caso, a coleta de dados e as atividades de tratamento
            dela decorrentes serão informadas aos usuários do site.
          </p>

          <h6 class="text-bold">
            3. Compartilhamento de dados pessoais com terceiros
          </h6>
          <p>
            Nós compartilhamos alguns dos dados pessoais mencionados nesta seção
            com terceiros.
          </p>
          <p>Os dados compartilhados são os seguintes:</p>
          <p>
            Alguns dados de Cadastro dos usuários são compartilhados com as
            empresas:
          </p>

          <p>
            AMBEV S/A <br />
            Assessoria Jurídica <br />
            SECAP órgão regulador <br />
            WeCheers
          </p>

          <p>
            Estes dados são compartilhados pelas seguintes razões e para as
            seguintes finalidades:
          </p>
          <p>
            O compartilhamento acontece para que as empresas possam medir a
            adesão da campanha, verificar a autenticidade e a segurança dos
            dados enviados de forma a auditar os resultados, coleta de
            informações de estabelecimentos, logística de abridores,
            estabelecimentos que estão ativos na promoção, número de usuários.
          </p>
          <p>
            Além das situações aqui informadas, é possível que compartilhemos
            dados com terceiros para cumprir alguma determinação legal ou
            regulatória, ou, ainda, para cumprir alguma ordem expedida por
            autoridade pública.
          </p>
          <p>
            Em qualquer caso, o compartilhamento de dados pessoais observará
            todas as leis e regras aplicáveis, buscando sempre garantir a
            segurança dos dados de nosso usuários, observados os padrões
            técnicos empregados no mercado.
          </p>

          <h6 class="text-bold">
            4. Por quanto tempo seus dados pessoais serão armazenados
          </h6>
          <p>
            Os dados pessoais coletados pelo site são armazenados e utilizados
            por período de tempo que corresponda ao necessário para atingir as
            finalidades elencadas neste documento e que considere os direitos de
            seus titulares, os direitos do controlador do site e as disposições
            legais ou regulatórias aplicáveis.
          </p>
          <p>
            Uma vez expirados os períodos de armazenamento dos dados pessoais,
            eles são removidos de nossas bases de dados ou anonimizados, salvo
            nos casos em que houver a possibilidade ou a necessidade de
            armazenamento em virtude de disposição legal ou regulatória.
          </p>

          <h6 class="text-bold">
            5. Bases legais para o tratamento de dados pessoais
          </h6>
          <p>
            Cada operação de tratamento de dados pessoais precisa ter um
            fundamento jurídico, ou seja, uma base legal, que nada mais é que
            uma justificativa que a autorize, prevista na Lei Geral de Proteção
            de Dados Pessoais.
          </p>
          <p>
            Todas as Nossas atividades de tratamento de dados pessoais possuem
            uma base legal que as fundamenta, dentre as permitidas pela
            legislação. Mais informações sobre as bases legais que utilizamos
            para operações de tratamento de dados pessoais específicas podem ser
            obtidas a partir de nossos canais de contato, informados ao final
            desta Política.
          </p>

          <h6 class="text-bold">
            1. Como o titular pode exercer seus direitos
          </h6>
          <p>
            Os titulares de dados pessoais tratados por nós poderão exercer seus
            direitos por meio do formulário disponibilizado no site da adesão da
            promoção. Alternativamente, se desejar, o titular poderá enviar um
            e-mail ou uma correspondência ao nosso Encarregado de Proteção de
            Dados Pessoais. As informações necessárias para isso estão na seção
            "Como entrar em contato conosco" desta Política de Privacidade.
          </p>
          <p>
            Os titulares de dados pessoais tratados por nós poderão exercer seus
            direitos a partir do envio de mensagem ao nosso Encarregado de
            Proteção de Dados Pessoais, seja por e-mail ou por correspondência.
            As informações necessárias para isso estão na seção "Como entrar em
            contato conosco" desta Política de Privacidade.
          </p>
          <p>
            Para garantir que o usuário que pretende exercer seus direitos é, de
            fato, o titular dos dados pessoais objeto da requisição, poderemos
            solicitar documentos ou outras informações que possam auxiliar em
            sua correta identificação, a fim de resguardar nossos direitos e os
            direitos de terceiros. Isto somente será feito, porém, se for
            absolutamente necessário, e o requerente receberá todas as
            informações relacionadas.
          </p>

          <h6 class="text-bold">
            7. Medidas de segurança no tratamento de dados pessoais
          </h6>
          <p>
            Empregamos medidas técnicas e organizativas aptas a proteger os
            dados pessoais de acessos não autorizados e de situações de
            destruição, perda, extravio ou alteração desses dados.
          </p>
          <p>
            As medidas que utilizamos levam em consideração a natureza dos
            dados, o contexto e a finalidade do tratamento, os riscos que uma
            eventual violação geraria para os direitos e liberdades do usuário,
            e os padrões atualmente empregados no mercado por empresas
            semelhantes à nossa.
          </p>
          <p>
            Entre as medidas de segurança adotadas por nós, destacamos as
            seguintes:
          </p>
          <ul>
            <li>- Armazenamento com dados de senhas criptografados</li>
            <li>- Monitoramento de servidores e banco de dados</li>
            <li>- Política interna de tratamento de dados</li>
          </ul>
          <p>
            Ainda que adote tudo o que está ao seu alcance para evitar
            incidentes de segurança, é possível que ocorra algum problema
            motivado exclusivamente por um terceiro - como em caso de ataques de
            hacker sou crackers ou, ainda, em caso de culpa exclusiva do
            usuário, que ocorre, por exemplo, quando ele mesmo transfere seus
            dados a terceiro. Assim, embora sejamos, em geral, responsáveis
            pelos dados pessoais que tratamos, nos eximimos de responsabilidade
            caso ocorra uma situação excepcional como essas, sobre as quais não
            temos nenhum tipo de controle.
          </p>

          <p>
            De qualquer forma, caso ocorra qualquer tipo de incidente de
            segurança que possa gerar risco ou dano relevante para qualquer de
            nossos usuários, comunicaremos os afetados e a Autoridade Nacional
            de Proteção de Dados acerca do ocorrido, em conformidade com o
            disposto na Lei Geral de Proteção de Dados.
          </p>

          <h6 class="text-bold">9. Alterações nesta política</h6>
          <p>
            A presente versão desta Política de Privacidade foi atualizada pela
            última vez em: 10/01/2023.
          </p>
          <p>
            Reservamo-nos o direito de modificar, a qualquer momento, as
            presentes normas, especialmente para adaptá-las às eventuais
            alterações feitas em nosso site, seja pela disponibilização de novas
            funcionalidades, seja pela supressão ou modificação daquelas já
            existentes.
          </p>
          <p>
            Sempre que houver uma modificação, nossos usuários serão notificados
            acerca da mudança.
          </p>

          <h6 class="text-bold">10. Como entrar em contato conosco</h6>
          <p>
            Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade
            ou sobre os dados pessoais que tratamos, entre em contato com nosso
            Encarregado de Proteção de Dados Pessoais, por algum dos canais
            mencionados abaixo:
          </p>
          <p>E-mail: suporte@ciadetrade.com.br</p>
          <p>Telefone: 11 38499535</p>
          <p>Endereço postal: Av. Nações Unidas 18801 - Sala 1120</p>
        </div>
        <label class="d-inline-flex align-items-center gap-2 mt-3">
          <input v-model="cadastradoPdv.form.aceite_termo" type="checkbox" />
          <p>
            Li e aceito os
            <span class="text-gray-100">
              Termos de Uso e Politica de privacidade.</span
            >
          </p>
        </label>
        <button
          type="button"
          :disabled="!cadastradoPdv.form.aceite_termo"
          class="btn w-100 rounded-pill mt-5"
          @click.prevent="cadastradoPdv.handleCadastro"
        >
          <div
            v-if="cadastradoPdv.loading"
            style="width: 1rem; height: 1rem"
            class="spinner-border text-secondary"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Aceitar</span>
        </button>
      </div>
      <div v-if="cadastradoPdv.selectedStep === 4">
        <Passo4 />
      </div>
      <!-- <div
        v-if="
          cadastradoPdv.selectedStep === 4 && !cadastradoPdv.cadastroSuccess
        "
      >
        <img src="../../assets/img/clicksignin.png" alt="" />
      </div> -->
      <div v-if="cadastradoPdv.selectedStep === 5">
        <h1 class="text-center my-4">
          Pronto! Que legal agora é só esperar os abridores chegarem!
        </h1>
        <p>
          Número do Protocolo:
          <strong>{{ cadastradoPdv.dadosSucesso.protocolo }}</strong>
        </p>
        <p>
          Previsão de entrega:
          <strong>{{ cadastradoPdv.dadosSucesso.prazo }}</strong>
        </p>
        <p>
          Email de Cadastro:
          <strong>{{ cadastradoPdv.dadosSucesso.email }}</strong>
        </p>
        <p>
          Telefone para contato:
          <strong>{{ cadastradoPdv.dadosSucesso.telefone }}</strong>
        </p>
        <p class="mt-5 text-center">
          Todos os contratos foram enviados também para o email cadastrado!
        </p>
      </div>
    </div>
    <div class="w-100">
      <p v-if="cadastradoPdv.selectedStep === 5" class="my-3 text-center">
        Se restou alguma dúvida fale conosco:
      </p>
      <p v-else class="mb-1 text-center">Precisa de ajuda?</p>

      <AjudaWPP />
    </div>
    <!-- <button
      v-if="cadastradoPdv.selectedStep === 5"
      type="button"
      class="btn w-100 rounded-pill mt-5"
      @click.prevent="cadastradoPdv.handleFinish"
    >
      <span>Acessar o painel de gerenciamento</span>
    </button> -->
  </form>

  <ModalComponent :btn-close="false" :open-modal="cadastradoPdv.confirmDados">
    <div>
      <h5 class="text-center mb-0">Confirme os dados que cadastrou:</h5>
      <p class="text-center">  
        <small class="text-orange">
          Você irá receber um TOKEN via {{cadastradoPdv.form.signature_method === 'sms' ? 'telefone no celular' : 'E-MAIL no email'}} informado acima para
              concluir sua assinatura
        </small>
      </p>
    </div>

    <div class="modal-body- py-2 flex-1">
      <p class="mb-0">Estabelecimento</p>
      <div
        v-for="(form, key) in cadastradoPdv.form.estabelecimento"
        :key="form"
      >
        <p class="capitalize text-bold">
          {{ key.replace("_", " ") }} : {{ form }}
        </p>
      </div>
      <div class="divider"></div>
      <p class="mb-0">Período</p>
      <div v-for="(form, key) in cadastradoPdv.form.periodo" :key="form">
        <p class="capitalize text-bold">
          {{ key.replace("_", " ") }} :
          {{ form.aberto ? "Aberto" : "Fechado" }}
          <span v-if="form.nGarcons">
            , Garçons:
            {{ form.nGarcons }}
          </span>
        </p>
      </div>
      <div class="divider"></div>
      <p class="mb-0">Responsável por assinar</p>
      <div v-for="(form, key) in cadastradoPdv.form.responsavel" :key="form">
        <p
          v-if="key !== 'registeredId'"
          class="capitalize text-bold"
          :class="{ 'text-orange': key.includes('telefone') }"
        >
          {{ key.replace("_", " ") === 'password' ? 'Senha' : key.replace("_", " ") }} : {{ form }}
        </p>
      </div>
    </div>
    <div class="row justify-content-center mt-4">
      <div class="col-md-6 d-flex justify-content-center">
        <button
          class="btn btn-modal-confirm rounded-pill px-3 bg-success"
          @click.prevent="cadastradoPdv.confirmForm"
        >
          Sim estão corretos.
        </button>
      </div>
      <div class="col-md-6 d-flex justify-content-center">
        <button
          class="btn btn-modal-confirm rounded-pill px-3 bg-danger"
          @click.prevent="cadastradoPdv.editForm"
        >
          Não, Preciso corrigir
        </button>
      </div>
    </div>
  </ModalComponent>

  <ModalComponent
    :open-modal="cadastradoPdv.showInstrucoes"
    @close-modal="cadastradoPdv.showInstrucoes = false"
  >
    <ComoFunciona class="mt-0" />
  </ModalComponent>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import ModalComponent from "@/components/ModalComponent.vue";
import { useCadastroPdv } from "../../store/cadastroPdv";
import Passo4 from "./Passo4.vue";
import ComoFunciona from "../../components/ComoFunciona.vue";
import AjudaWPP from "../../components/AjudaWPP.vue";
import { validaCpfCnpj } from "@/utils/validacpfCnpj";

const router = useRouter()
const cadastradoPdv = useCadastroPdv();
const confirma_email = ref('')
const confirma_telefone = ref('')
const errorEmail = ref(false)
const errorTelefone = ref(false)
const validaPeriodo = computed(() => {
  const keys = Object.keys(cadastradoPdv.form.periodo);
  return keys.reduce((acc, item) => {
    if (cadastradoPdv.form.periodo[item].aberto) {
      acc += 1;
    }
    return acc > 0;
  }, 0);
});

const disabledStep = computed(() => {
  let disabled = true;
  if (
    cadastradoPdv.form.estabelecimento.cnpj &&
    validaCpfCnpj(
      cadastradoPdv.form.estabelecimento.cnpj
        .replace(/\./g, "")
        .replace(/-/g, "")
        .replace(/\//g, "")
    ) &&
    cadastradoPdv.searchCNPJ
  ) {
    disabled = false;
  } else if (
    !cadastradoPdv.form.estabelecimento.nome_estabelecimento ||
    !cadastradoPdv.form.estabelecimento.cnpj ||
    !cadastradoPdv.form.estabelecimento.cep ||
    !cadastradoPdv.form.estabelecimento.rua ||
    !cadastradoPdv.form.estabelecimento.numero ||
    !cadastradoPdv.form.estabelecimento.email ||
    !validaPeriodo.value
  ) {
    disabled = true;
  } else {
    disabled = false;
  }

  return disabled;
});
const disabledStep2 = computed(() => {
  let disabled = true;

  if (
    !cadastradoPdv.form.responsavel.nome ||
    !cadastradoPdv.form.responsavel.telefone ||
    !cadastradoPdv.form.responsavel.email ||
    !cadastradoPdv.form.responsavel.cpf ||
    !cadastradoPdv.form.responsavel.password ||
    cadastradoPdv.form.signature_method === 'sms' && confirma_telefone.value !== cadastradoPdv.form.responsavel.telefone ||
    cadastradoPdv.form.signature_method === 'email' && confirma_email.value !== cadastradoPdv.form.responsavel.email
  ) {
    disabled = true;
  } else {
    disabled = false;
  }

  return disabled;
});

watch(
  () => cadastradoPdv.form.responsavel.cpf,
  (newValue) => {
    const value = newValue
      ? newValue.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "")
      : "";
    if (value && validaCpfCnpj(value)) {
      cadastradoPdv.checkCpf(value);
    }
  }
);
watch(
  () => confirma_email.value,
  (newValue) => {  
    if (cadastradoPdv.form.signature_method === 'email' && newValue !== cadastradoPdv.form.responsavel.email) {
      errorEmail.value = true
    }else {
      errorEmail.value = false
    }
  }
);
watch(
  () => confirma_telefone.value,
  (newValue) => {  
    if (cadastradoPdv.form.signature_method === 'sms' && newValue !== cadastradoPdv.form.responsavel.telefone) {
      errorTelefone.value = true
    } else {
      errorTelefone.value = false
    }
  }
);
onMounted(() => {
  router.push({ name: 'Login' })
  cadastradoPdv.aviso_cadastro_encerrado = true
  // cadastradoPdv.Validartoken();
});
</script>

<style scoped>
h1 {
  font-size: 30px;
  color: #000218;
  text-align: left;
}

.btn-modal-confirm {
  width: 100%;
  max-width: 202px;
}

.n-step {
  font-weight: bold;
  font-size: 25px;
  background-color: #e6ecf2;
  height: 39px;
  width: 39px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccd2d7;
}

.n-step.selected {
  font-size: 30px;
  background-color: #272727;
  height: 46px;
  width: 46px;

  color: #fff;
}

.form {
  width: 100%;
  max-width: 550px;
}

.btn {
  padding: 0.75rem;
  font-size: 16px;
  background-color: #272727;
  color: #fff;
}

.btn.chat {
  background-color: transparent;
  color: #18a76a;
  border: solid 1px #18a76a;
  width: 185px;
}

.text-gray-100 {
  color: #707070;
}

a:hover {
  text-decoration: underline;
  color: red !important;
}

.periodo .form-control {
  max-width: 198px;
}

ul {
  list-style-type: none;
}

.text-bold {
  font-weight: bold;
}

.politica-area {
  max-height: 683px;
  overflow: auto;
}

.politica-area p {
  line-height: 1.4;
  margin-bottom: 10px !important;
  font-size: 0.9rem;
}

.modal-body- p {
  font-size: 0.9rem;
  line-height: 1.3;
}
</style>
