<template>
    <section class="bg-white py-5 section-resgate position-relative d-flex justify-content-between area-picpay">
        <div class="area-l">
            <div>
                <h1 class="">RESGATE SEUS PONTOS PELO
                    PICPAY, VEJA COMO FUNCIONA:</h1>
            </div>
            <div class="card d-inline-flex flex-column p-3">
                <div class="d-flex align-items-center text-center">
                    <div>
                        <p class="numbers">10.000</p>
                        <p class="desc">pontos no Bees da Chefia</p>
                    </div>
                    <p class="numbers mx-1">=</p>
                    <div>
                        <p class="numbers valor">10,00</p>
                        <p class="desc credito">em créditos PicPay </p>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between text-center">
                    <!-- <p class="desc">pontos no Bees da Chefia</p> -->
                    <!-- <p class="desc">em créditos PicPay </p> -->
                </div>
            </div>
            <img class="cel" src="../../assets/img/Cel.png" alt="App">
        </div>
        <div><img class="img-fluid" src="../../assets/img/PicPay.svg" alt="PicPay"></div>
        <div class="area-l-mask"></div>
    </section>
</template>

<style scoped>
h1 {
    font-size: 30px;
    color: #FFF;
    background-color: #000;
    display: inline-block;
    padding: 5px 15px;
}

.section-resgate h1 {
    color: #000;
    background-color: transparent;
    max-width: 540px;
}

.desc {
    font-weight: 600;
    padding: 0 15px;
}

.valor {
    position: relative;
    margin-left: 20px !important;
}

.valor::before {
    content: 'R$';
    position: absolute;
    font-size: 18px;
    bottom: 0 ;
    left: -10px;
}

.area-l {
    display: inline-block;
    padding-left: 64px;
    padding-bottom: 29px;
    padding-top: 47px;
    width: 100%;
    max-width: 822px;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    position: relative;
    z-index: 12;
    background-color: #FFFF00;
}

.area-l-mask {
    display: block;
    width: 870px;
    height: 300px;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    background-color: #000;
    position: absolute;
    top: 18px;
    left: 0;
    z-index: 0;
}




.numbers {
    font-size: 71px;
    font-weight: bold;
    height: 94px;
}

.cel {
    top: -28px;
    position: absolute;
    right: -89px;
}

@media(max-width:768px) {
    .area-l {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;   
}
  .area-picpay {
    flex-direction: column;
  }

  .area-l-mask {
    width: 270px;
    height: 300px;
  }


  .cel {
    display: none;
  }
   .numbers {
    font-size: 61px;
    height: 82px;
}

 
}
@media(max-width:580px) {
  .numbers {
    font-size: 44px;
    height: 62px;
} 
.desc {
    padding: 0px;
}
.valor::before {
    content: 'R$';
    position: absolute;
    font-size: 18px;
    bottom: 0 ;
    left: -15px;
}
}
@media(max-width:440px) {
  .numbers {
    font-size: 36px;
    height: 51px;
}
.valor::before {
    font-size: 14px; 
}
.desc {
    font-size: 12px;
}
}
@media(max-width:405px) {
  .desc.credito{
    max-width: 108px;
}
}
</style>