<script setup>
import { useRoute, useRouter } from "vue-router";
import { computed } from 'vue';
// import { auth } from "@/store/auth";
// const authStore = auth();
const route = useRoute();
const router = useRouter();

const props = defineProps({
  showCat: { Boolean, default: false },
});
// function handleComecar() {
//   if (!authStore.token) {
//     router.push({ name: "FormPdv" });
//   } else {
//     router.push({ name: "Painel" });
//   }
// }

const categoria = computed(() => {
  return route.name === 'PageGarcom' ? 'Sou dono do bar':'Sou garçom' 
})

function handleCategoria() {
  route.name === 'PageGarcom' ? router.push({ name: 'PageDono' }) : router.push({ name: 'PageGarcom' })
}
</script>

<template>
  <section class=" pt-5 position-relative bg-primary">
    <button v-if="props.showCat" class="categoria-btn" @click="handleCategoria">{{ categoria }}</button>
    <div
:class="{ 'mt-5': route.name !== 'PageGarcom' }"
      class="d-flex flex-column flex-md-row justify-content-between position-relative">
      <img v-if="route.name !== 'HomeV2'" class="abelha" src="../assets/img/Abelha.png" alt="abelha">
      <div class="m-0 me-md-7">
        <img
v-if="route.name === 'PageGarcom' || route.name === 'HomeV2'" class="img-fluid" src="../assets/img/Banner-abridor-txt.png"
          alt="banner" />
        <img v-else class="img-fluid" src="../assets/img/Banner-abridor-txt-dono.png" alt="banner" />
        <p v-if="route.name !== 'HomeV2'" class="text-regulamento pb-2">Participação válida para pessoas jurídicas,
          maiores de 18 anos (inclusive
          representante legal da PJ) desde que
          inscritas na plataforma BEES e participantes do "Club B" até a data de
          início desta promoção, entre 11/09/2023 e 11/01/2024 até 23:59 (horário de Brasíla) sendo limitada a R$700,00
          por
          participante durante todo período de participação. Antes de
          participar, Leia e aceite o regulamento no APP Bees da Chefia, <strong>
            Consulte o regulamento e certificado de autorização
            SEAE em APP Bees da Chefia.
          </strong>
          <br />
          <strong><i>*podendo ser prorrogado.</i></strong>
        </p>
      </div>
      <div class="d-flex align-items-end banner"><img
class="img-fluid" src="../assets/img/Banner-abridor.png"
          alt="banner" /></div>
    </div>
  </section>
</template>

<style scoped>
.categoria-btn {
  padding: 15px 17px;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: bold;
  border: solid 1px #000;
  position: absolute;
  right: 30px;
  z-index: 1;
  top: 16px;
}

.abelha {
  position: absolute;
  left: 50%;
  bottom: -53px;
}

.text-regulamento {
  max-width: 708px;
  font-size: 9px;
  font-weight: 600;
  line-height: 1.3;
  padding-left: 95px;
}

.btn-main {
  padding: 12px 30px;
  position: absolute;
  min-height: 80px;
  font-size: 25px;
  bottom: -40px;
}

.mobile .btn-main {
  bottom: -130px;
}

h1 {
  font-size: 30px;
  color: #000218;
}

.mobile {
  display: none;
}

@media (max-width: 768px) {
  .text-regulamento {

    padding-left: 5px;
  }

  .banner {
    display: none !important;
  }

  .abelha {
    display: none;
  }

  section.mobile {
    margin-bottom: 155px !important;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .categoria-btn {
    top: 5px;
    font-size: 14px;
  }

}

.banner-mobile {
  background-image: url(../assets/img/banner-mobile.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  max-width: 413px;
  height: 415px;
  margin: auto;
  position: relative;
}

.banner-mobile h1::before {
  content: "";
  display: block;
  height: 2.5px;
  width: 30px;
  background-color: #fffd38;
  margin-bottom: 10px;
}

.banner-mobile h1 {
  font-size: 23px;
  max-width: 143px;
}

.banner-mobile p {
  max-width: 240px;
  font-size: 13px;
  font-weight: 700;
}

.text-abridor {
  max-width: 169px !important;
  font-size: 15px !important;
  position: absolute;
  bottom: 120px;
  left: 174px;
}



@media (max-width: 414px) {
  .banner-mobile p {
    max-width: 230px;
    font-weight: 700;
  }

  .btn-main {
    font-size: 18px;
  }
}
</style>
