<template>
    <section class="py-5 area-aviso">
        <div class="d-flex justify-content-center">
            <h3 class="destac">Importante</h3>
        </div>
        <p>Abridores inativos a mais de 15 dias poderão ser recolhidos.</p>
        <div class="divider"></div>
        <p>Os abridores devem permanecer no estabelecimento e devem ser
            carregados diariamente.</p>
    </section>
</template>

<style scoped>
.destac {
    padding: 8px 16px;
    background-color: #FFFD38;
    color: #000000;
    font-size: 45px;
    font-weight: 500;
    width: 100%;
    max-width: 336px;
    display: flex;
    justify-content: center;
}

.divider {
    height: 1px;
    background-color: #FFFD38;
    width: 80%;
    margin: auto;
}

.area-aviso {
    background-color: #000;
}

.area-aviso p {
    background-color: #000;
    font-size: 30px;
    color: #FFFD38;
    font-weight: bold;
    text-align: center;
}
</style>