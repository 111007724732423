<script setup>
</script>

<template>
  <footer class="bg-primary py-4">
    <div class="d-md-flex justify-content-between align-items-center container">
      <div class="area-regulamento">
        <h2>BEBA COM MODERAÇÃO</h2>
        <p>Participação válida para pessoas jurídicas, maiores de 18 anos (inclusive representante legal da PJ) desde que
          inscritas na plataforma BEES e participantes do "Club B" até a data de
          início desta promoção, entre 11/09/2023 e 11/01/2024 até 23:59 (horário de Brasíla) sendo limitada a R$700,00
          por
          participante durante todo período de participação. Antes de
          participar, Leia e aceite o regulamento no APP Bees da Chefia, <strong>
            Consulte o regulamento e certificado de autorização
            SEAE em APP Bees da Chefia.
          </strong>
          <br />
          <strong><i>*podendo ser prorrogado.</i></strong>
        </p>
      </div>
      <div><img src="../assets/img/logo-chefia.png" alt="logo"></div>
    </div>
  </footer>
</template>

<style scoped>
.area-regulamento {
  max-width: 767px;
}

.area-regulamento p {
  font-size: 9px;
  font-weight: 600;
  line-height: 1.3;
}

img {
  max-width: 163px;
}
</style>
