import { defineStore } from 'pinia'
import { http } from '@/services/api';

import { validaCpfCnpj } from "@/utils/validacpfCnpj";
import { preloaderStore } from "@/store/preloaderStore";
import { auth } from "@/store/auth";
import { useCadastroProcurador } from "@/store/cadastroProcurador";

import router from '../router'
import { useRoute } from 'vue-router';
let preloader, authStore

setTimeout(() => {
    preloader = preloaderStore();
    authStore = auth();

}, 100);

export const useCadastroPdv = defineStore('cadastroPdv', {
    state: () =>
    ({
        aviso_cadastro_encerrado:false,
        disabled: {
            cnpj: false,
            wifi: false,
            numero: false,
            rua: false,
            complemento: false,
            cep: false,
            nome_estabelecimento: false,
            telefone_estabelecimento: false,
            cpf: false,
            nome: false,
        },
        isMobile: false,
        showInstrucoes: false,
        add_procurador: false,
        loadingCep: false,
        loading: false,
        cadastroSuccess: false,
        confirmDados: false,
        searchCNPJ: false,
        searchCPF: true,
        selectedStep: 1,
        request_signature_key: '',
        form: {
            signature_method: 'sms',
            estabelecimento: {
                estabelecimento_id: '',
                nome_estabelecimento: "",
                cnpj: "",
                cep: "",
                rua: "",
                numero: "",
                complemento: "",
                email: "",
                telefone_estabelecimento: "",
                wifi: 'Não'
            },
            periodo: {
                manha: { aberto: false, nGarcons: "" },
                tarde: { aberto: false, nGarcons: "" },
                noite: { aberto: false, nGarcons: "" },
            },
            responsavel: {
                registeredId: '',
                nome: "",
                telefone: "",
                email: "",
                password: "",
                cpf: "",
            },
            aceite_termo: false,
        },
        dadosSucesso: {}
    }),
    actions: {

        ActionCleanState() {
            this.showInstrucoes = false
            this.add_procurador = false
            this.loadingCep = false
            this.loading = false
            this.cadastroSuccess = false
            this.confirmDados = false
            this.searchCNPJ = false
            this.searchCPF = true
            this.selectedStep = 1
            this.request_signature_key = ''
            this.disabled = {
                cnpj: false,
                wifi: false,
                numero: false,
                rua: false,
                complemento: false,
                cep: false,
                nome_estabelecimento: false,
                telefone_estabelecimento: false,
                cpf: false,
                nome: false,
            },
                this.form = {
                    estabelecimento: {
                        nome_estabelecimento: "",
                        cnpj: "",
                        cep: "",
                        rua: "",
                        numero: "",
                        complemento: "",
                        email: "",
                        telefone_estabelecimento: "",
                    },
                    periodo: {
                        manha: { aberto: false, nGarcons: "" },
                        tarde: { aberto: false, nGarcons: "" },
                        noite: { aberto: false, nGarcons: "" },
                    },
                    responsavel: {
                        registeredId: '',
                        nome: "",
                        telefone: "",
                        email: "",
                        password: "",
                        cpf: "",
                    },
                    aceite_termo: false,
                }
        },

        handleisMobile() {
            this.isMobile = window.innerWidth < 768;
        },

        buscaCep() {
            const cep = this.form.estabelecimento.cep
                .replace(/-/g, "")
                .replace(/\//g, "");
            if (cep.length !== 8)
                return window.__Swal.fire({
                    title: "Atenção",
                    icon: "warning",
                    text: "confira o cep digitado!",
                });

            this.loadingCep = true;
            const options = {
                method: "GET",
                mode: "cors",
                cache: "default",
            };
            if (cep.length === 8) {
                fetch(`https://viacep.com.br/ws/${cep}/json/`, options).then((r) => {
                    r.json().then((data) => {
                        if (!data.erro) {
                            const { logradouro } = data;
                            this.form.estabelecimento.rua = logradouro;
                            this.loadingCep = false;
                        } else {
                            window.__Swal.fire({
                                text: "Cep não encontrado, verifique e tente novamente!",
                                icon: "warning",
                            });
                            this.form.estabelecimento.cep = "";

                            this.loadingCep = false;
                        }
                    });
                });
            }

            this.loadingCep = false;
        },

        checkNomeCompleto() {
            let valido = this.form.responsavel.nome.trim().split(' ').length > 1

            if (!valido) {
                window.__Swal.fire({
                    icon: "warning",
                    title: "Atenção",
                    text: 'Preencher o nome completo',
                });
            }
        },

        checkEmail() {
            const url = 'checkEmail'
            return http.post(url, { email: this.form.responsavel.email }).then(r => {
                if (!r.data.success) {
                    window.__Swal.fire({
                        icon: "warning",
                        title: "Atenção",
                        text: r.data.message,
                    }).then(() => {
                        this.form.responsavel.email = ''
                    })
                }
            })
        },

        validaCnpjCpf(doc) {
            let valido = validaCpfCnpj(
                doc.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "")
            );

            if (!valido) {
                if (this.selectedStep === 1) {
                    this.form.estabelecimento.cnpj = "";
                } else {
                    this.form.responsavel.cpf = "";
                }
                window.__Swal.fire({
                    icon: "warning",
                    title: "Atenção",
                    text: `${this.selectedStep === 1 ? "CNPJ" : "CPF"
                        } inválido`,
                });
            }
        },

        limpaMask(str) {
            return str ? str.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "").replace(/\(/g, "").replace(/\)/g, "").replace(/\(/g, "").replace(" ", "") : ''
        },

        checkCnpj(cnpj) {
            const url = 'checkCnpj'
            return http.post(url, { cnpj }).then((r) => {
                return r
            })
        },
        async checkCpf(cpf) {

            if (this.searchCPF) {
                try {
                    const cadastradoProcurador = useCadastroProcurador();
                    this.loading = true
                    const url = 'checkCpf'
                    const r = await http.post(url, { cpf, estabelecimento_id: this.form.estabelecimento.estabelecimento_id })

                    if (r.code === 400) { /* ativa para cadastrar procurador*/
                        cadastradoProcurador.form.cnpj = this.form.estabelecimento.cnpj
                        cadastradoProcurador.form.cpf = this.form.responsavel.cpf
                        this.add_procurador = true
                        return
                    }
                    if (r.code === 401) { /* limpa o form */
                        this.ActionCleanState()
                        return
                    }

                    if (r && r.data && r.data.usuario && r.data.usuario.registeredId) { /* se o cpf informado ja possuo cadastro ativo, redireciona para login */
                        await window.__Swal.fire({
                            text: 'Identificamos que você ja possui um cadastro ativo, faça o login para continuar.',
                            icon: "warning",
                        });
                        this.form.responsavel.registeredId = r.data.usuario.registeredId
                        return router.push({ name: "Login" });
                    }
                    if (r.data.success) {
                        this.form.responsavel.nome = r.data.usuario.name
                        this.form.responsavel.cpf = r.data.usuario.cpf
                        this.searchCPF = false
                        this.disabled.cpf = true
                        this.disabled.nome = true
                        this.add_procurador = false
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    this.loading = false
                }


            }
        },

        async handleCadastro() {

            if (this.searchCNPJ) { /* se o cadastro nao é via link e precisa buscar o cnpj */

                try {
                    await this.Validartoken()
                } catch (error) {
                    console.log(error)
                }
                return console.log('Buscar cnpj')
            }

            if (this.selectedStep === 1) {
                this.selectedStep = 2;
            } else if (this.selectedStep === 2) {
                document.body.style.overflow = 'hidden'
                this.confirmDados = true
            } else if (this.selectedStep === 3) {
                try {
                    preloader.setPreloader(true); let formCadastro = JSON.parse(JSON.stringify(this.form));
                    formCadastro.periodo.manha.nGarcons === ""
                        ? (formCadastro.periodo.manha.nGarcons = 0)
                        : null;
                    formCadastro.periodo.tarde.nGarcons === ""
                        ? (formCadastro.periodo.tarde.nGarcons = 0)
                        : null;
                    formCadastro.periodo.noite.nGarcons === ""
                        ? (formCadastro.periodo.noite.nGarcons = 0)
                        : null;
                    formCadastro.responsavel.cpf = this.limpaMask(formCadastro.responsavel.cpf)
                    formCadastro.estabelecimento.cnpj = this.limpaMask(formCadastro.estabelecimento.cnpj)
                    delete formCadastro.responsavel.registeredId
                    const res = await this.sendForm(formCadastro)
                    this.form.responsavel.registeredId = ''
                    if (res.success) {
                        this.request_signature_key = res.data.request_signature_key
                        this.selectedStep = 4;
                    }

                } catch (error) {
                    console.log(error)
                } finally {
                    preloader.setPreloader(false);
                }
            } else if (this.selectedStep === 4) {
                this.selectedStep = 5;
            } else {
                // loading.value = true;
                // try {
                //   await authStore.ActionDoLogin(form);
                //   await authStore.ActionGetProfile();
                //   router.push({ name: "Dash" });
                // } catch (error) {
                //   console.log(error);
                // } finally {
                //   loading.value = false;
                // }
            }
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },

        sendForm(payload) {
            const url = 'register'
            return http.post(url, payload).then((r) => {
                authStore.user = r.data.data
                authStore.token = r.data.token
                return r.data
            })
        },

        handleFinish() {
            this.ActionCleanState()
            router.push({ name: "Login" });
        },

        getProtocol() {
            const url = 'getProtocol'
            return http.post(url, { id: authStore.user.id }).then((r) => {
                this.dadosSucesso = r.data
                return r.data
            })
        },


        // aqui vai validar o tokendps
        async Validartoken() {
            const route = useRoute()
            const token = route ? route.params.token : ''
            this.searchCNPJ = !token
            let cnpjSend = ''
            if (this.searchCNPJ && !this.form.estabelecimento.cnpj) return
            if (this.searchCNPJ && this.form.estabelecimento.cnpj) {
                cnpjSend = this.form.estabelecimento.cnpj.replace(/\./g, "")
                    .replace(/-/g, "")
                    .replace(/\//g, "")
            }
            if (token) {
                cnpjSend = token
            }
            try {
                this.loading = true
                const r = await this.checkCnpj(cnpjSend)

                if (r?.code) {
                    if (r?.code == 404) {
                        window.__Swal.fire({
                            text: r.data.msg,
                            icon: "warning",
                        });
                        router.push({ name: 'Home' })
                    } else if (r?.code == 401) {
                        window.__Swal.fire({
                            text: r.data.msg,
                            icon: "warning",
                        });
                        router.push({ name: 'Login' })
                    }
                }
                if (r?.data) {

                    this.form.estabelecimento.cep = r?.data?.estabelecimento?.cep;
                    this.form.estabelecimento.rua = r?.data?.estabelecimento?.address;
                    this.form.estabelecimento.wifi = r?.data?.estabelecimento?.hasWifi;
                    this.form.estabelecimento.cnpj = r?.data?.estabelecimento?.cnpj;
                    this.form.estabelecimento.complemento = r?.data?.estabelecimento?.complement;
                    this.form.estabelecimento.nome_estabelecimento = r?.data?.estabelecimento?.name;
                    this.form.estabelecimento.estabelecimento_id = r?.data?.estabelecimento?.id;
                    this.form.estabelecimento.numero = r?.data?.estabelecimento?.numbers;
                    this.form.estabelecimento.telefone_estabelecimento = this.phoneMask(r?.data?.estabelecimento?.phone);
                    this.disabled.cep = !!this.form.estabelecimento.cep;
                    this.disabled.rua = !!this.form.estabelecimento.rua;
                    // this.disabled.wifi = !!this.form.estabelecimento.wifi;
                    this.disabled.cnpj = !!this.form.estabelecimento.cnpj;
                    this.disabled.complemento = !!this.form.estabelecimento.complemento;
                    this.disabled.nome_estabelecimento = !!this.form.estabelecimento.nome_estabelecimento;
                    this.disabled.numero = !!this.form.estabelecimento.numero;
                    this.disabled.telefone_estabelecimento = !!this.form.estabelecimento.telefone_estabelecimento;
                }

                this.searchCNPJ = false


            } catch (error) {
                console.log(error)
                // router.push({ name: 'Home' })
            } finally {
                this.loading = false
            }
        },

        phoneMask(value) {
            if (!value) return ""
            if (value.length <= 11) {
                value = value.replace(/\D/g, '')
                value = value.replace(/(\d{2})(\d)/, "($1) $2")
                value = value.replace(/(\d)(\d{4})$/, "$1-$2")
            }
            return value
        },

        editForm() {
            this.selectedStep = 1
            this.confirmDados = false
            document.body.style.overflow = 'auto'
        },

        confirmForm() {
            this.confirmDados = false
            this.selectedStep = 3
            document.body.style.overflow = 'auto'
        },

        handleLimitGarcons() {
            if (this.form.periodo.tarde.nGarcons > 10) {
                this.form.periodo.tarde.nGarcons = 10
            }
            if (this.form.periodo.noite.nGarcons > 10) {
                this.form.periodo.noite.nGarcons = 10
            }
            if (this.form.periodo.manha.nGarcons > 10) {
                this.form.periodo.manha.nGarcons = 10
            }
        }
    },
})