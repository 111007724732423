<template>
  <main
    class="main-content position-relative"
    :class="[authStore.token ? '' : 'bg-light']"
  >
    <preloader />
    <!-- <navbar v-if="showNavbar" /> -->
    <component :is="layout" />
  </main>
</template>
<script setup>
import { auth } from "@/store/auth";
import { useCadastroPdv } from "@/store/cadastroPdv";
import { onMounted, computed } from "vue";
import { useRoute } from "vue-router";
// import Sidenav from "./examples/Sidenav";
// import Configurator from "@/examples/Configurator.vue";
// import Navbar from "@/examples/Navbars/Navbar.vue";
import Preloader from "./components/Preloader.vue";
const cadastradoPdv = useCadastroPdv();
const authStore = auth();
const route = useRoute();
const layout = computed(() => {
  return route.meta.layout || "Layout-default";
});

onMounted(() => {
  // this.authStore.ActionCheckToken();
  cadastradoPdv.handleisMobile();
  window.addEventListener("resize", cadastradoPdv.handleisMobile);
});
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,500&display=swap");

body,
html {
  font-family: "Montserrat", sans-serif;
  color: #000218 !important;
  font-size: 15px;
}

p {
  margin: 0 !important;
}

button {
  background-color: transparent;
  border: 0;
}

label {
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
}

.flex-1 {
  flex: 1;
}

.bg-light {
  background: #f7f7f7;
}

.bg-blue-2 {
  background-color: #184780 !important;
}

.capitalize {
  text-transform: capitalize;
}

.text-orange {
  color: #ff6f00;
}
.text-gray {
  color: #707070;
}
.underline {
  text-decoration: underline;
}
.divider {
  height: 2px;
  width: 100%;
  background-color: #707070;
  margin: 10px 0;
}

.vs__dropdown-toggle {
  border: 0 !important;
}

.cursor-auto {
  cursor: auto;
}

input[type="datetime-local"],
input[type="date"] {
  outline: 0;
  position: relative;
  width: 100%;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="date"]::after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("./assets/icons/calendar.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

a:hover {
  color: inherit !important;
  opacity: 0.8;
}

.btn-success {
  color: #000;
  background-color: #8ebc42 !important;
}

.categoria-btn:hover {
  background-color: #000;
  color: #fffd38;
}

*::-webkit-scrollbar {
  height: 8px;
  width: 3px;
}

*::-webkit-scrollbar-track {
  width: 90%;
  background-color: rgb(189, 189, 189, 20%);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(189, 189, 189, 80%);
  border-radius: 9999px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgb(189, 189, 189, 100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
