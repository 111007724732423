<template>
  <div
    :class="{ 'h-100': !cadastradoPdv.isMobile }"
    class="d-flex flex-column justify-content-center align-items-center"
  >
    <form class="form-login">
      <div class="text-center mb-4">
        <h1>Entre com seu login</h1>
        <h5 v-if="cadastradoPdv.aviso_cadastro_encerrado">Período de cadastro encerrado!</h5>
      </div>
      <div class="input-group input-group-lg mb-3">
        <input
          v-model="form.email"
          type="text"
          class="form-control"
          aria-label="Username"
          aria-describedby="basic-addon1"
          placeholder="E-mail"
          @keyup.enter="handleLogin"
        />
      </div>
      <div class="input-group input-group-lg mb-3">
        <input
          v-model="form.password"
          type="password"
          class="form-control"
          aria-label="password"
          aria-describedby="basic-addon2"
          placeholder="Senha"
          @keyup.enter="handleLogin"
        />
      </div>

      <p class="my-4">
        Ao continuar, você concorda com nosso Aviso de Privacidade.
      </p>

      <button
        type="button"
        class="btn w-100 rounded-pill"
        @click.prevent="handleLogin"
      >
        <div
          v-if="loading"
          style="width: 1rem; height: 1rem"
          class="spinner-border text-secondary"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <span v-else>Entrar</span>
      </button>

      <button
        type="button"
        class="btn btn-n-entra w-100 rounded-pill"
        @click.prevent="openModal = true"
      >
        <span>Não consigo entrar</span>
      </button>
    </form>

    <ModalComponent :open-modal="openModal" @close-modal="openModal = false">
      <div class="w-100">
        <p class="float-left">Recuperar senha</p>
      </div>
      <div class="d-flex justify-content-center">
        <div class="w-100 recuperar-area">
          <label for="cpfCnpj">Confirme o CPF</label>
          <input
            id="cpf"
            v-model="recover.cpf"
            v-maska="'###.###.###-##'"
            name="cpf"
            class="mb-4 form-control"
            type="text"
          />
        </div>
      </div>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <button class="btn btn-primary me-3" @click="openModal = false">
          Cancelar
        </button>
        <button class="btn btn-success" @click="handleRecover">Enviar</button>
      </div>
    </ModalComponent>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import ModalComponent from "@/components/ModalComponent.vue";
import { validaCpfCnpj } from "@/utils/validacpfCnpj";
import { preloaderStore } from "@/store/preloaderStore";
import { useCadastroPdv } from "@/store/cadastroPdv";

import { auth } from "@/store/auth";
const authStore = auth();
const cadastradoPdv = useCadastroPdv();
const preloader = preloaderStore();

const router = useRouter();

const loading = ref(false);
const openModal = ref(false);

const form = reactive({
  email: "",
  password: "",
});
const recover = reactive({
  cpf: "",
});

async function handleRecoverSend() {
  const cpf = recover.cpf
    .replace(/\./g, "")
    .replace(/-/g, "")
    .replace(/\//g, "");
  let key = cpf.length === 11 ? "cpf" : "cnpj";

  try {
    const res = await authStore.ActionForgotPassword({
      [key]: cpf,
    });

    window.__Swal.fire({
      icon: res.data.success ? "success" : "warning",
      text: res.data.message,
    });

  } catch (error) {
    console.log(error);
  } finally {
    openModal.value = false
  }
}

function handleRecover() {
  const cpf = recover.cpf
    .replace(/\./g, "")
    .replace(/-/g, "")
    .replace(/\//g, "");
  let cpfValido = false;

  if (!cpf) {
    return window.__Swal.fire({
      text: "Campo vazio, verifique e tenta novamente",
      icon: "warning",
    });
  }
  if (cpf.length === 11 || cpf.length === 14) {
    cpfValido = validaCpfCnpj(cpf);
  } else {
    return window.__Swal.fire({
      text: "CNPJ ou CPF invalido!",
      icon: "error",
    });
  }

  if (!cpfValido) {
    return window.__Swal.fire({
      text: "CPF invalido!",
      icon: "error",
    });
  }

  handleRecoverSend();
}

async function handleLogin() {
  try {
    preloader.setPreloader(true);
    authStore.token = "tokenMocado";

    const body = new FormData();
    body.append("email", form.email);
    body.append("password", form.password);
    const res = await authStore.ActionDoLogin(body);

    if (res.data.status) {
      if (cadastradoPdv.form.responsavel.registeredId) {
        cadastradoPdv.selectedStep = 3;        
        router.push({ name: "FormPdv" });
      } else {
        router.push({ name: "Painel" });
      }
    } else if (res.data.status === 0) {
      cadastradoPdv.request_signature_key = res.data.request_signature;
      cadastradoPdv.selectedStep = 4;
      router.push({ name: "FormPdv", params: { token: res.data.cnpj } });
    }
  } catch (error) {
    console.log(error);
    if (error.status !== 401) {
      if (error.data) {
        window.__Swal.fire({
          icon: "error",
          title: error.data.error.title || "Oops...",
          text: error.data.error.message,
        });
      } else {
        window.__Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocorreu um erro inesperado, favor contactar o suporte.",
        });
      }
    }
  } finally {
    preloader.setPreloader(false);
  }
}
</script>

<style scoped>
h1 {
  font-size: 30px;
  color: #000218;
}
.form-login {
  width: 100%;
  max-width: 500px;
}

.btn {
  padding: 0.75rem;
  font-size: 16px;
  background-color: #272727;
  color: #fff;
}
.btn.btn-n-entra {
  background-color: #f1f2f3;
  color: #747980;
}
</style>
